
$('.menu-button').click(function(e) {
    e.preventDefault();

    $('header nav').toggleClass('mobile-show');
});

$('.close-menu').click(function(e) {
    e.preventDefault();

    $('header nav').toggleClass('mobile-show');
});