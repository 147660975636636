const $block = $('section.latest-news');

if($block.length > 0) {
    let container = $block.find('.current-post')[0];
    $block.find('.posts article a').click(function() {
        let slug = $(this).attr('href').replace('#', '');
        getPosts(slug);
    });

    function getPosts(slug) {
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('slug', slug);
        $.ajax({
            type: 'POST',
            url: rk_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                container.innerHTML = res;
                container.setAttribute('id', slug);
                $('html,body').animate({
                    scrollTop: $("#" + slug).offset().top - 80
                }, 300);
            }
        });
    }

    if (window.location.hash) {
        var target = window.location.hash,
        target = target.replace('#', '');
        getPosts(target);
    }

}
