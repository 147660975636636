$('.red-body .continue-indicator a').click(function (e) {
    if ($(this).attr('href') === '#') {
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.parents('.red-body');
        var $next = $parent.next('*');
        
        if ($next.length > 0) {
            $('html, body').animate({
                scrollTop: $next.offset().top - 80
            }, 400);
        }
    }
});